import React from "react"
import { graphql } from "gatsby"

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faLastfm,
  faMastodon,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { faKey, faRss } from "@fortawesome/free-solid-svg-icons"
import tw from "twin.macro"
import { IconLinkInterface, IndexPageInterface } from "../@types/pages"
import HCard from "../components/hCard"
config.autoAddCss = false

export const query = graphql`
  query {
    site {
      siteMetadata {
        fullName
        siteImage {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

const IconLink: React.FC<IconLinkInterface> = ({
  href,
  title,
  icon,
  rel = "me",
}) => {
  const Container = styled.a`
    ${tw`font-normal`}
  `

  const Icon = styled(FontAwesomeIcon)`
    ${tw`text-3xl ml-5`}
  `

  return (
    <Container href={href} title={title} rel={rel}>
      <Icon icon={icon} />
    </Container>
  )
}

const IndexPage: React.FC<IndexPageInterface> = ({ data }) => {
  const IndexContainer = styled.section`
    ${tw`
      flex flex-col items-center justify-center text-center
      mt-16
      xl:mt-56
      xl:mb-24
      `}
  `
  const Avatar = styled(Img)`
    ${tw`
    rounded-full w-48
    md:w-64
    `}
  `

  const Name = styled.h1`
    ${tw`text-3xl font-bold mt-2 font-index`};
  `

  const Tag = styled.h2`
    ${tw`text-2xl font-bold mt-2 font-index`}
  `

  const Icons = styled.div`
    ${tw`flex mt-5 w-9/12 flex-wrap justify-center`}
  `

  return (
    <Layout>
      <SEO title="Home" />
      <HCard />
      <IndexContainer>
        {data.site.siteMetadata.siteImage !== null ? (
          <Avatar
            fluid={data.site.siteMetadata.siteImage.childImageSharp.fluid}
          />
        ) : (
          ""
        )}
        <Name>{data.site.siteMetadata.fullName}</Name>
        <Tag>I build stuff and complain here from time to time.</Tag>
        <Icons>
          <IconLink
            href="https://github.com/alrayyes"
            title={"Github"}
            icon={faGithub}
          />
          <IconLink
            href="https://twitter.com/alrayyes"
            title={"Twitter"}
            icon={faTwitter}
          />
          <IconLink
            href="https://last.fm/user/alrayyes"
            title={"Last.fm"}
            icon={faLastfm}
          />
          <IconLink
            href="/keys/ryankes.asc"
            title={"My public PGP key"}
            icon={faKey}
            rel={""}
          />
          <IconLink
            href="/rss.xml"
            title={"Site RSS feed"}
            icon={faRss}
            rel={""}
          />
          <IconLink
            href="https://social.ryankes.eu/@ryan"
            title={"My Mastodon profile"}
            icon={faMastodon}
          />
        </Icons>
      </IndexContainer>
    </Layout>
  )
}

export default IndexPage
